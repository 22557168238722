import { FC } from "react";

import { SearchBarProps } from "./SearchBar.interfaces";
import {Input} from "theme-ui";

const SearchBar:FC<SearchBarProps> = (props) => {
const {onInput, placeholder} = props;
    return (
        <Input onInput={onInput} placeholder={placeholder}/>
    );
}

export default SearchBar;