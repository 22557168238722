import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from "./views/Home";
import Suggestions from "./views/Suggestions";
import SuggestionContext from "./context/SuggestionContext";
import SuggestionContextProvider from "./context/SuggestionContext";

function App() {
  return (
    <div className="App">
      <SuggestionContextProvider>
        <Router>
          <Switch>
            <Route path="/suggestions">
              <Suggestions />
            </Route>
            <Route exact={true} path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </SuggestionContextProvider>

    </div>
  );
}

export default App;
