import {FC, useContext} from "react";

import { SuggestionsProps } from "./Suggestions.interfaces";
import {
    ActGroupTitle, ActItem, ActItemClickWrapper, ActItemSuggestionCount,
    ActItemWrapper,
    BackButton,
    BackNavigationWrapper, NoSuggestionsFoundTitle, StageTitle,
    SuggestionTitle, TimeSlotGroup, TimeSlotTitle,
    TimeTableDateWrapper, TimeTableDayTitleWrapper, TimeTableSlot, TimeTableSlotWrapper
} from "../Home/Home.styled";
import {SuggestionContext} from "../../context/SuggestionContext";
import {ArtistTimeTableSlot, ArtistWithFusionContext} from "../../../client/LastFM";
import TimeTable from "../../acts/actTimetable.json";
import { useHistory } from 'react-router-dom';
import {InnerWrapper, SuggestionsWrapper} from "./Suggestions.styled";

const dayToIndexMap:{[index: number]: number} = {
    3:0,
    4:1,
    5:2,
    6:3,
    0:4,
    1:5
}
const getTimeSlotString = (start:string, end:string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return `${startDate.toLocaleTimeString("de-DE", {hour: "2-digit", minute:"2-digit"})} - ${endDate.toLocaleTimeString("de-DE", {hour: "2-digit", minute:"2-digit"})}`
}
const mapArtistsToTimeTable = (selectedArtists:string[], suggestions:ArtistWithFusionContext[]) => {
    const days: {[index: string]: {
            name: string,
            stage: string,
            count?: number,
            isSelected?:boolean
        }[]}[]= [{},{},{},{},{},{}];
    const sortedTimeTable:ArtistTimeTableSlot[] = (TimeTable as ArtistTimeTableSlot[]).sort((a,b) => {
        return new Date(a.start).getTime() - new Date(b.start).getTime();
    });

    sortedTimeTable.forEach(artistWithSlot => {
        let artistAdded = false;
        const startDay = dayToIndexMap[new Date(artistWithSlot.start).getDay()];
        const timeSlot = getTimeSlotString(artistWithSlot.start, artistWithSlot.end);
        if(selectedArtists.includes(artistWithSlot.artist)){
            if(startDay !== undefined) {
                if(!days[startDay][timeSlot]){
                    days[startDay][timeSlot] = [{
                        name: artistWithSlot.artist,
                        stage: artistWithSlot.floor,
                        isSelected: true
                    }]
                    artistAdded = true;
                } else {
                    days[startDay][timeSlot].push({
                        name: artistWithSlot.artist,
                        stage: artistWithSlot.floor,
                        isSelected: true
                    })
                    artistAdded = true;
                }
            }
        } else if(!artistAdded && suggestions.findIndex((sug) => artistWithSlot.artist === sug.name) > -1) {
            let count = 0;
            suggestions.forEach(suggestedArtist => {
                if(suggestedArtist.name === artistWithSlot.artist){
                    count += 1;
                }
            })
            const suggestedArtist = suggestions.find(sug => sug.name === artistWithSlot.artist);
            if(!days[startDay][timeSlot]){
                days[startDay][timeSlot] = [{
                    name: artistWithSlot.artist,
                    stage: artistWithSlot.floor,
                    count
                }]
                artistAdded = true;
            } else {
                days[startDay][timeSlot].push({
                    name: artistWithSlot.artist,
                    stage: artistWithSlot.floor,
                    count
                })
                artistAdded = true;
            }
        }
    });
    return days;
}

const Suggestions:FC<SuggestionsProps> = (props) => {
    const history = useHistory();
const {suggestions, selectedArtists} = useContext(SuggestionContext)
    return (
      <SuggestionsWrapper>
          <InnerWrapper>
                  <BackNavigationWrapper>
                      <BackButton onClick={() => history.push("/")}>Zurück</BackButton>
                  </BackNavigationWrapper>
                  <SuggestionTitle>Deine Empfehlungen</SuggestionTitle>
                  <ActItemWrapper>

                      { suggestions.length > 0 && mapArtistsToTimeTable(selectedArtists, suggestions).map((daySlot, index) => {
                          if(Object.keys(daySlot).length > 0){
                              return <TimeTableDateWrapper key={`date#${index}`}>
                                  <TimeTableDayTitleWrapper>
                                      <ActGroupTitle>{["Mittwoch","Donnerstag","Freitag","Samstag","Sonntag","Montag",][index]}</ActGroupTitle>
                                  </TimeTableDayTitleWrapper>
                                  <TimeTableSlotWrapper>
                                      {Object.keys(daySlot).map((timeSlotKey, i) => (
                                        <TimeTableSlot key={`daySlot#${i}`}>
                                            <>
                                                <TimeSlotTitle>{timeSlotKey}</TimeSlotTitle>
                                                {daySlot[timeSlotKey].map((slot, index) => {
                                                    return <TimeSlotGroup key={`timeslot#${i}-#${index}`}>
                                                        <StageTitle>{slot.stage}</StageTitle>
                                                        <ActItemClickWrapper key={`empfehlung#${index}`}>
                                                            { slot.count !== undefined && (
                                                              <ActItemSuggestionCount aria-label={"Anzahl von Empfehlungen"}>{slot.count}</ActItemSuggestionCount>
                                                            )}
                                                            { slot.isSelected && (
                                                              <ActItemSuggestionCount aria-label={"Anzahl von Empfehlungen"}>★</ActItemSuggestionCount>
                                                            )}
                                                            <ActItem isSelected={false}>{slot.name}</ActItem>
                                                        </ActItemClickWrapper>
                                                    </TimeSlotGroup>

                                                })}
                                            </>
                                        </TimeTableSlot>
                                      ))}

                                  </TimeTableSlotWrapper>
                              </TimeTableDateWrapper>
                          }
                      })}
                      {suggestions.length === 0 && (
                        <NoSuggestionsFoundTitle>Leider haben wir keine passenden Empfehlungen gefunden.</NoSuggestionsFoundTitle>
                      )}
                  </ActItemWrapper>
          </InnerWrapper>
      </SuggestionsWrapper>

    );
}

export default Suggestions;