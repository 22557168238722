export const djs: string [] = [
  "10 Mark DJ Team",
  "2 Girls 1 Club",
  "300 km/h",
  "A5KM",
  "ABIBA",
  "ABLA 101",
  "A.D.H.S.",
  "AEON FLUX",
  "AFRO LATIN WHAT?!",
  "AfroOankali",
  "afro slot crew feat dj boas & oliver",
  "Afterclapp",
  "AIKON",
  "Alchemiah",
  "Alex.Do",
  "Alice D",
  "Alinka",
  "Alma Linda",
  "Amoss & Sense MC",
  "Amuleto Manuela y Calamidades Lola",
  "ANDI A",
  "Andi Theke",
  "Andreas Henneberg",
  "André Winter",
  "Andreya Casablanca",
  "AndShe",
  "Andy Kolwes",
  "ANIMAL TRAINER",
  "Anna Reusch",
  "Annca",
  "Annett Gapstream",
  "Antoine Baiser",
  "Anton Jacobsson",
  "Antr",
  "Apolonia",
  "Armando Letico",
  "Artéria FM",
  "Ash Roy b2b Laxberger",
  "ASNA",
  "ATA",
  "Atzenbogy",
  "Audio Werner",
  "audite & Sense MC",
  "Avi & Ahmed + Kuba",
  "Balout Krew",
  "Bambii",
  "Barbarella",
  "Barthez & Giotto",
  "Bassphilia",
  "Bebetta",
  "Bebibelli",
  "Beda",
  "Best Boy Electric",
  "Be Svendsen",
  "Billi DeStabl",
  "Bizzarro Universe",
  "BKLXA",
  "Blazej Malinowski",
  "Blocks & Escher",
  "BOBBIE*",
  "Bonfante",
  "Bonnie Ford",
  "Boom Shankar",
  "Bootsmann ft. Wortfetzen MC",
  "Booty Terror",
  "Britta Arnold",
  "BRKN1 & Nola Fuchs",
  "Bruno Ritmos Cholulteka",
  "Bud Dancer",
  "BUKKHA",
  "Caleesi & Sarah Kreis",
  "Camion Bazar",
  "Canaan I ft. Jah Moby & Lord Mathias (Rockers Ark)",
  "Captain Hook",
  "Captain of None",
  "Cara Carpaccio",
  "Caravel",
  "Carlita",
  "Carolain Luf",
  "Cash Miri",
  "Casimir Von Oettingen b2b Electronic Elephant",
  "Caspar",
  "Cater Karlos & Coco Loris",
  "Cathitah Steppah",
  "Catu Diosis",
  "Chai Kowski",
  "Chaos Katy",
  "Charley White & his Mic",
  "Charrua & Nüx Füdisch",
  "Chrisse Kunst",
  "Chrizzlix",
  "Chrystal Chris",
  "Cincity",
  "Cioz",
  "Claudio Chinotto",
  "Claus Fussek",
  "Coco Loris",
  "Connexx",
  "Contact High",
  "Crille & Tamalt",
  "Cyan85",
  "Daferwa",
  "Damon Jee",
  "Dana Anderson",
  "Dandelion Soundsystem",
  "Dandelion Soundsystem ft. Conscious Fiyah",
  "Dangermami",
  "Daniel Haaksman",
  "Daniel Krau",
  "Danny Scrilla",
  "Datacult",
  "Daunbert",
  "Dave Dinger & Avocado",
  "David Dorad",
  "Decibella",
  "Deer Jade",
  "DEJAUS",
  "Der E-Kreisel",
  "Der Loth",
  "Derun",
  "Desiree",
  "Detox Dan",
  "Diashi (Tuntenhaushoffest)",
  "Die blauen Stunden",
  "Distortion 3000",
  "Djane Gaby",
  "Djane Melburn",
  "Djane Obstsalat",
  "DJ Ausbruch",
  "DJ BarbNerdy",
  "DJ Burg",
  "DJ Costalero",
  "DJ Dusty Warbler",
  "DJ Frottee",
  "DJ Fuckoff",
  "DJ Gigola",
  "DJ Heartstring",
  "Dj Heart XL",
  "DJ Heronymuz + Gül Umay",
  "DJ Ipek Ipekcioglu",
  "DJ K",
  "DJ Karawai",
  "DJ Mell G",
  "DJ Nosemos",
  "DJ Oblig",
  "Dj Oldschooltreff",
  "DJ Plümpe",
  "DJ Ripley",
  "DJ Sloush+Zarrt",
  "DJ SM6 & Djoulaï & VJ Rita Parker",
  "DJ Supermarkt",
  "DJ Sweet6teen",
  "DJ Teichkind",
  "DJ Wahid Paradis",
  "DJ Zhao (Ngoma Sound)",
  "Donna Luetjen",
  "Don Plok",
  "Dr Calavera",
  "Drumbule",
  "dsty_",
  "Dub Isotope & Goldnickel",
  "Edna Martinez",
  "Educut",
  "Eightball",
  "Einzman & Doc Dressla",
  "El Batos",
  "Electric Indigo",
  "Elevator LIzz",
  "Elizen The Emperor",
  "Elli Acula",
  "Elliver",
  "Elmar Strathe",
  "EL_P",
  "El Zisco",
  "Eric Brwn",
  "Esclé",
  "Esshar",
  "Es.tereo",
  "Esther Silex & Madmotormiquel",
  "Eule:nhaupt & Molle:nhauer",
  "Ezgi",
  "Face*",
  "Faultierdisko",
  "Femmefloor",
  "Filou & Davydova",
  "Finndus",
  "Fitz",
  "Flo. M",
  "Foolik",
  "Franca",
  "Franz Scala",
  "Fred",
  "FreiDinger",
  "Frida Darko & Atric",
  "GATURAMA",
  "Gazbee",
  "Geballa",
  "Geju",
  "Georg Wedel",
  "Gina Sabatini",
  "GÎN BALI",
  "GiZ",
  "Global Warming",
  "Gontard & Fuchs",
  "Gorge & Markus Homm",
  "Gr33nhornZ",
  "Gretchen Bazooka",
  "GrimeGrimeGrime",
  "GrimeGrimeGrime & Club 27",
  "GrimeGrimeGrime & Club 27",
  "Günni Gatzen",
  "Gustav Krøne",
  "Gwen Wayne b2b Päris",
  "Gwen Wayne & Lina",
  "Haensen & Gretel",
  "Hanaby",
  "Hannah Wants",
  "Haunted Water",
  "Heimlich Knüller",
  "Hermine Flanger",
  "herrhoppe",
  "Icicle & MC Mantmast",
  "Ida Daugaard",
  "I Double m-o",
  "Igor Amore",
  "Ika Duchna b2b Reka Zalan",
  "Immanuel Yacob",
  "Inity Session",
  "insəˈkyo͝orədē ˈɡɑrdz",
  "Iron Curtis",
  "Ivy Lab",
  "J666L",
  "Jaamann",
  "Jackie Jackpot",
  "Jake The Rapper",
  "Jamiie",
  "Jan Kinsey",
  "Jennifer Touch",
  "Jimi Handtrix (Rolling Lion Hi-Fi)",
  "Jiser",
  "Johnny Bobo",
  "Jon1st",
  "Jorine",
  "Jose Noventa",
  "Josephine Wedekind",
  "JourneyOM",
  "Joyce Muniz",
  "Juba",
  "Judith van Waterkant",
  "Julia Govor",
  "Juliane Wolf",
  "Julian Jeweil",
  "Julio Paradise",
  "Kainat",
  "Kalaha Moon",
  "Kali Avaaz",
  "Kangding Ray",
  "Kaputse",
  "Karate Xabi",
  "Kaschpasoundz ft. Amando",
  "Katja Komorebi",
  "Katka",
  "Katzele",
  "Katzengold",
  "Katzenohr",
  "Katz-k",
  "Kaufmann",
  "Keene",
  "Keinemusik (&ME, Adam Port, Rampa, Reznik)",
  "KGLN",
  "KI/KI",
  "kikki_1000",
  "King E",
  "K-Isuma & Psyvana",
  "KJUBI",
  "Klackwerk",
  "Klaus DJ",
  "kluntje",
  "kMIMIZAN",
  "kobpy",
  "Kotoe",
  "Kotterzür",
  "Krachtigall Ultras",
  "Kraftfuttermischwerk",
  "KRAN",
  "Krell & Sereno",
  "Kutter bei die Fische - Fusion 2022",
  "Laaaametta",
  "Lady Oelectric",
  "Laetizia",
  "La Loakaii ft. MC Treasure Irie",
  "lay:z & D3Bug",
  "le.fu",
  "Leggy BLOND",
  "Lemonella",
  "Lena Mega & LoA",
  "Lenia",
  "Lenny Fuck",
  "L.F.T.",
  "Lina & Neele",
  "lindos xp b2b eves120",
  "Lisa tba",
  "Lisbird b2b HOVR",
  "Lislbar & Wermuth",
  "L Major",
  "Loco",
  "Lola Luc",
  "Lola Villa",
  "lona µ.",
  "loora",
  "Lorenza Laserstein b2b Stella Slower b2b Lupo Manero (Italian Dance Wave)",
  "Loui Beton b2b TARS",
  "Lou Nautic",
  "LUI",
  "Luigi Di Venere",
  "LUZ1E",
  "LWT",
  "LXC",
  "MaKa",
  "Mala & Rebella",
  "Malte Piet",
  "Malu Mujer",
  "Mama Snake",
  "Mantara",
  "Marco Baskind",
  "Marco Costanza",
  "Maria Kremeti",
  "Marie Lung (Dreaming Forever)",
  "Marie Midori",
  "Marie Montexier",
  "Marlon Hoffstadt b2b Malugi",
  "Martha van Straaten",
  "Mary Jane",
  "Mascha",
  "Mathias Kaden",
  "Mat.Joe",
  "matschenka",
  "Mauler & Glashägen",
  "Maximono",
  "Meabia",
  "Meg10",
  "Mehr is Mehr",
  "Melbo & Falke",
  "Metaboman",
  "Mettabbana",
  "Mika Dutsch",
  "Mila Stern",
  "MILK ME",
  "Milo",
  "M I M I",
  "Mimi Love",
  "Mina da Lua",
  "Minco",
  "Mira and Chris Schwarzwalder",
  "Miriam Schulte",
  "Misanthrop",
  "Mixanthrope",
  "mobilegirl",
  "Mo Chan / DJ Kohlrabi",
  "Moderna",
  "Moiselle",
  "Mollono.Bass",
  "Motin",
  "Mr. Ciao b2b Ni:vek",
  "Mrs Magoo",
  "Mr. Vast",
  "MTHR BRCH",
  "Muamba Masala",
  "Murmel",
  "Mystery Affair",
  "Najesh & Artišoko",
  "Nas Tea",
  "Neele",
  "Nickodemus (Wonderwheel Recordings)",
  "Njette",
  "non+ultras",
  "Nozem",
  "Nur Simon",
  "Nyembamba",
  "Nymfo & Sense MC",
  "Oakin x Anaconda",
  "Obermannkind",
  "Oberst & Buchner",
  "Oliver Huntemann",
  "Oliver Koletzki",
  "Olivia Mendez",
  "Ondula",
  "Ouzo b2b Kataya",
  "Oxia",
  "Pacaya",
  "Pan-Pot",
  "Pärchenurlaub",
  "Parole Emil! b2b Spoonman",
  "Passage",
  "PATRICE BÄUMEL",
  "Paul Gregor",
  "Pauli Pocket",
  "Paul Ritch",
  "Pavelo & Giorgio",
  "Pelle Buys",
  "PermaCouture",
  "Pete Gelée",
  "Pete Rowbots",
  "Philipp Strobel",
  "Philo",
  "Pieter Kock",
  "Planet 8",
  "Plugdub",
  "PopHop",
  "Popkulturelle Referenz",
  "POPOF",
  "Potensia",
  "Power Suff Girls",
  "Prismode & Solvane",
  "Prophet Love",
  "Punani",
  "Pupa Vinz",
  "Purrygon",
  "Radio Djan Djan",
  "Radio Reudnitz",
  "Rafush + DJ Goodboy",
  "RAIDERS Showcase: Souci B2B Tamila, jpeg.love (Live), $ombi B2B DJ Nortside",
  "RanchRelax",
  "Raulito Wolf",
  "Rauschhaus",
  "Reckless",
  "Red Stork",
  "Rene Bourgeois",
  "Reple",
  "Rey&Kjavik",
  "Rhabia",
  "Rødhåd",
  "Rodion Levin",
  "Roman Ratsche",
  "Roots Descendents ft. Ical Ises",
  "Rosa Pistola",
  "Rrrr",
  "R Vincenzo",
  "SAGAN",
  "Sama`",
  "Sam Goku",
  "Sam Shure",
  "sandix",
  "Sarah Farina",
  "Sarah Wild",
  "Sattadub",
  "Schmuuv",
  "Scooby Dub",
  "Scout Wölfli CherrYO!kie MegaMix",
  "Scratchynski",
  "Sdunic",
  "Sedef Adasi",
  "Sega Lee",
  "SELEKTOR BONY",
  "Semprey",
  "Sensei Lo",
  "Set Ambessa",
  "Sheila",
  "Sherry b2b Slare",
  "Shimza",
  "Shinedoe",
  "ShloMo Lilienthal",
  "Shubostar",
  "Shu Shu",
  "Sightiva HIFI",
  "Sire Jonah",
  "Sista Sherin",
  "SLIC unit",
  "Slimcase",
  "SOLEE",
  "Sonido Los Cariños",
  "Soulsurfer",
  "Spindyrella",
  "Stages",
  "Staxl",
  "Stephan Zovsky",
  "Stop & Go",
  "Südseemelodien",
  "Sui",
  "Super Flu",
  "Suse & Elliver",
  "sutsche",
  "Sven Dohse",
  "Sven Weisemann",
  "Sylvie Maziarz",
  "Sylvio B.",
  "Tanith",
  "Tegnigma",
  "Terr",
  "The Happiest Couple On Earth",
  "Thundy86",
  "Tiago Oudman",
  "Timboletti",
  "TJ White Rabbit",
  "Tom Baker & Jedidiah",
  "Tom Finster",
  "Tony Turner b2b Reiner Rheinsberg",
  "Tornado Wallace",
  "Toroki & Isayah Piyah Zawa",
  "Township Rebellion",
  "Trypas Corassão / Cigarra",
  "T.X.B",
  "Uff",
  "Ulan Bator - Base & Derrick feat. Mystic Dan",
  "Ulan Bator & Doc Dressla",
  "Ulan Bator meets Knagge & Doc Dressla",
  "Vanessa Bettina",
  "Victor Ruiz",
  "VILIFY",
  "Vincent Vidal",
  "Vince Watson",
  "Vlada",
  "Von D",
  "VVET",
  "WAKA",
  "Walter Griot & Ukai",
  "Wevie",
  "WobWob!",
  "Workforce & MC Mantmast",
  "Wosto",
  "Xenaia",
  "Xrysafeniax",
  "Yesim",
  "Yondo and Lorca",
  "Yorobi",
  "Yoru",
  "Yotam Avni",
  "Yulia Kachan",
  "Yung Coco",
  "Zayn Kemp",
  "Zazou",
  "ZigMon",
  "Zitroni"
];