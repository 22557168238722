export const bands: string[] = [
  "24/7 DIVA HEAVEN",
  "Abarra",
  "Acid Amazonians",
  "Afar",
  "Afrodelic",
  "Aggregat",
  "Akktenzeichen",
  "Alex Henry Foster",
  "Algiers",
  "Alli Neumann",
  "Alogte Oho & His Sounds of Joy",
  "Altin Gün",
  "Alto Fuero",
  "Amewu",
  "Anika",
  "Anti-Corpos",
  "Antinational Bass Crew",
  "A Panda do Sol",
  "ARAT KILO feat MAMANI KEITA feat MIKE LADD",
  "Attack Of The Mad Axeman",
  "Auf Sendung",
  "Ayom",
  "Ay Wing",
  "AYYUKA",
  "Azmari",
  "BaBa ZuLa",
  "Baby of Control",
  "Baby Of The Bunch",
  "Baby's Berserk",
  "Bad Affair",
  "Bahnhof Motte",
  "BANTUNAGOJÊJE",
  "BEACHPEOPLE",
  "BĘÃTFÓØT",
  "Betterov",
  "Big Mountain County",
  "Bipolar Feminin",
  "BlackSquare",
  "Blanco Teta",
  "Blectum from Blechdom",
  "Bonaparte & Sophie Hunger",
  "Braindead",
  "Briefbombe",
  "Britney's Fears",
  "Buntspecht",
  "Burnout Ostwest",
  "Calle Mambo",
  "Cataphiles",
  "Cava",
  "Chillera",
  "Chouk Bwa & The Ångströmers",
  "Cloud Kinski",
  "Cloud Managment",
  "C'mon Tigre",
  "Colour your Mind",
  "Coogans Bluff",
  "Copy",
  "Créme Solaire",
  "Crowskin",
  "Cuntroaches",
  "CV Vision",
  "Cyril Cyril",
  "Dalai Puma",
  "DANGER DAN",
  "Das Tonarmreich",
  "Decolonize Your Mind Society",
  "Deli Girls",
  "Die Verlierer",
  "Die Wände",
  "Dikanda",
  "Dismalfucker",
  "Dog Dimension",
  "Egg Idiot",
  "Emaskulatör",
  "Equiknoxx ft. Gavsborg, Shanique Marie & Time Cow",
  "Esels Alptraum",
  "Ester Poly",
  "Euroteuro",
  "Fallwander",
  "Famous",
  "Farhot",
  "Farhot",
  "Fatigue",
  "F*cking Angry",
  "Febueder",
  "Fermium",
  "Finisterre",
  "FO SHO",
  "Frente Cumbiero",
  "Fulu Miziki",
  "Genn",
  "Geo",
  "Geraldine Schabraque X Vander Lizm",
  "Get Jealous",
  "Ghetto Kumbe",
  "Gilla Band",
  "Gladbeck City Bombing",
  "Glauque",
  "GOAT",
  "Golden Dawn Arkestra",
  "Grosstadtgeflüster",
  "Groupe RTD - The Dancing Devils of Djibouti",
  "Guacayo",
  "Guadal Tejaz",
  "Gute Katze Böse Katze",
  "Gym Tonic",
  "Halfsilks",
  "Hammerhead",
  "Hania Rani",
  "Harry Charles",
  "Have You Ever Seen The Jane Fonda Aerobic VHS?",
  "HC BAXXTER",
  "Hector Savage",
  "Henge",
  "Hyparschall",
  "IC3PEAK",
  "Ida Nielsen and The Funkbots",
  "Ikan Hyu",
  "Il Civetto",
  "Irnini Mons",
  "Islandman",
  "JAMES BKS & The New Breed Gang",
  "Jasmine Lajeunesse & Mellie Meteors",
  "Jealous",
  "Jembaa Groove",
  "Jenny Pepps & Robkob 600e",
  "Joanna Gemma Auguri",
  "Johannes Klingebiel",
  "Kackschlacht",
  "Kadavar",
  "Kalaha Moon",
  "Ketekalles",
  "Kileen / Kosmofon Live Session",
  "King Automatic",
  "King Hannah",
  "King Khan & The Shrines",
  "Kirszenbaum",
  "Kit Sebastian",
  "K.I.Z",
  "Kokoko!",
  "Kollaps",
  "Korto",
  "Kosy",
  "Kröter",
  "La Gallera Social Club",
  "La Perla",
  "Laturb",
  "Leoniden",
  "Les Trucs",
  "Liiek",
  "Loitra Beuys",
  "London Afrobeat Collective",
  "Lotto",
  "Lucie & The Robot",
  "Lucy Kruger & The Lost Boys",
  "Machete en boca",
  "Mafia Klvb",
  "Magik Karpet Karavan",
  "MaidaVale",
  "Malatesta",
  "Mal Élevé",
  "Mamoré",
  "Mariama",
  "Mark Ernestus‘ Ndagga Rhythm Force",
  "Matibhrama, Heimya, Gnaia",
  "Matibhrama, Heimya, Gnaia",
  "Matibhrama, Heimya, Gnaia",
  "Matrone",
  "Meloi",
  "MESSED UP",
  "Meute",
  "Miley Silence",
  "Mine",
  "Mirandéle",
  "Mirna Bogdanovic Group",
  "Missratene Töchter",
  "Mitsune",
  "Mitsune",
  "Mono Green",
  "Moody Kablawi",
  "Morena Leraba",
  "Morgen Teuer Töten",
  "Moscow Death Brigade",
  "Mourning [A] BLKstar",
  "Neonschwarz",
  "Newen Afrobeat",
  "new_project_666",
  "Nihiloxica",
  "Norlyz",
  "NOWAVES",
  "Oidorno",
  "Oiseaux-Tempête",
  "Olicía",
  "Oliotronix",
  "Oluma",
  "Omni Selassi",
  "Oracle Sisters",
  "Painting",
  "Paulinchen Brennt",
  "Peaches",
  "Perilymph",
  "Petrol Girls",
  "Phonophobia",
  "Pietra Tonale Orchestra, Salgari Rec., Andi Otto, M.Rux & guests",
  "Plattenbau",
  "Pogendroblem",
  "Point No Point",
  "POKY",
  "Pongo",
  "Ponys Auf Pump",
  "Postman",
  "Public Display of Affection",
  "Puddles Rising ft The Creatures From The Deep",
  "Puppe'n Mucke",
  "PUULUUP",
  "PVA",
  "Qlowski",
  "Quintron & Miss Pussycat",
  "Rimojeki",
  "Rocket Men",
  "Rolf Blumig",
  "Rong Kong Koma",
  "Rosa Blaulicht",
  "Rosa Mimosa y sus Mariposas",
  "Roter Hering",
  "Ryskinder",
  "Saint Michael",
  "Saitün",
  "SALOMEA",
  "Sandaru Sathsara",
  "Santiago Analytica",
  "Santrofi",
  "Sara Hebe",
  "Schildkrötenunterfunktion",
  "Schroety Schamone",
  "Shaolin X Tracy",
  "Shey Rah",
  "Shht",
  "Shirley Holmes",
  "Shortparis",
  "Sind",
  "Snapped Ankles",
  "snarg",
  "Son Rompe Pera",
  "Sophistication.",
  "Steppenkind",
  "Strongboi",
  "Sub Pressure Band feat. Longfingah & Baptiste",
  "SUCK",
  "Sylvie Kreusch",
  "Takeshi's Cashew",
  "Taxi Kebab",
  "Team Scheisse",
  "Testimona",
  "The Cosmic Progress Orchestra",
  "The Lips",
  "The Magic Touch",
  "The OhOhOhs",
  "THE OHOHOHS feat. Maja Bader & OMNIAstrings",
  "The Omniversal Earkestra",
  "The Stancats",
  "The Zenmenn",
  "Tiao",
  "TIPP",
  "Tom Gatza",
  "Trak Trak",
  "Trixsi",
  "Turbostaat",
  "Umlaut",
  "Urban Voodoo Machine",
  "Voodoo Jürgens",
  "Wanubale",
  "Warm Graves",
  "Warsaw Village Band",
  "Wayne Snow",
  "we don't suck, we blow!",
  "WELLENBRECHER",
  "Wendt Band Zu Dritt",
  "Wick Bambix",
  "Wojczech",
  "Wonderska",
  "Wu-Lu",
  "Yin Yin"
];