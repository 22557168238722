import {FC, useCallback, useContext, useEffect, useState} from "react";

import { HomeProps } from "./Home.interfaces";
import {
  ActGroup,
  ActGroupHeadWrapper,
  ActGroupNavButton,
  ActGroupNavWrapper,
  ActGroupOffsetLabel,
  ActGroupTitle,
  ActItem,
  ActItemClickWrapper,
  ActItemWrapper,
  ActOuterWrapper,
  HomeWrapper,
  InnerWrapper,
  ResetButton,
  SearchWrapper,
  SuggestionButton,
  SuggestionButtonWrapper,
} from "./Home.styled";
import {Text} from "theme-ui";
import SearchBar from "../../components/SearchBar";
import Fuse from "fuse.js";
import { useHistory } from 'react-router-dom';

import {djs, bands, liveActs} from "../../acts"
import ActRelations from "../../acts/actRelations.json";

import { ArtistWithFusionContext} from "../../../client/LastFM";
import {SuggestionContext} from "../../context/SuggestionContext";

const ARTIST_LIMIT = 20;
const SELECTED_ARTISTS_STORAGE_KEY = "selectedArtists"
const getMinMaxOfArray = (src:string[], offset:number, limit:number) => {
  return {
    min: offset,
    max: (src.length - offset) < limit ? src.length : offset + limit
  }
}

const Home:FC<HomeProps> = (props) => {
  const {selectedArtists, setSelectedArtist, suggestions, setSuggestions, djOffset, setDJOffset, bandOffset, setBandOffset, liveActOffset, setLiveActOffset} = useContext(SuggestionContext);
  const history = useHistory();
  const [searchResults, setSearchResults] = useState([]);

  const [showSuggestions, setShowSuggestions] = useState(false);

  const actsWithContext = (ActRelations as {acts: ArtistWithFusionContext[]}).acts;
  const similarArtistFuse = new Fuse<ArtistWithFusionContext>(actsWithContext, {
    includeScore: false,
    keys: ['rootArtist'],
    threshold: 0,
  });
  const fusionArtistFuse = new Fuse([...djs,...bands,...liveActs], {
    includeScore: true,
    keys: ['name'],
    threshold: 0.25,
    ignoreLocation: true,
    minMatchCharLength: 4
  })

  const searchSimilarArtists = useCallback((searchQuery: string) => {
    const searchResult = similarArtistFuse.search(searchQuery);
    if(searchResult && searchResult.length > 0){
      return searchResult.map(sr => sr.item);
    }
    return [];
  }, [])
  const searchFusionArtists = useCallback((searchQuery: string) => {
    const searchResult = fusionArtistFuse.search(searchQuery);
    if(searchResult && searchResult.length > 0){
      return searchResult.map(sr => sr.item);
    }
    return [];
  }, [])
  const toggleSelectedArtist = useCallback((artistName: string) => {
    if(selectedArtists.includes(artistName)){
      const _selectedArtist = [...selectedArtists.filter(_artist => _artist !== artistName)]
      setSelectedArtist(_selectedArtist);
    } else {
      const _selectedArtist = [...selectedArtists, artistName]
      setSelectedArtist(_selectedArtist);
    }
  }, [selectedArtists, setSelectedArtist])

  const artistIsSelected = useCallback((artistName: string) => {
    return selectedArtists.includes(artistName);
  }, [selectedArtists, setSelectedArtist])

  const generateSuggestions = useCallback(() => {
    let suggestions = [];
    selectedArtists.forEach(selectedArtist => {
      const similar = searchSimilarArtists(selectedArtist);
      suggestions.push(...similar);
    });
    setSuggestions(suggestions);
  }, [selectedArtists, setSuggestions])

  const mapSuggestions = useCallback(() => {
    const suggestionCountMap:{[index: string]:  ArtistWithFusionContext & {count: number}} = {};
    suggestions.forEach(sug => {
      if(!suggestionCountMap[sug.name]){
        suggestionCountMap[sug.name] = {
          ...sug,
          count: 1
        }
      } else {
        suggestionCountMap[sug.name] = {
          ...sug,
          count: suggestionCountMap[sug.name].count + 1
        }
      }
    });
    return Object.values(suggestionCountMap);
  }, [suggestions]);

  useEffect(() => {
    localStorage.setItem(SELECTED_ARTISTS_STORAGE_KEY, JSON.stringify(selectedArtists));
  }, [selectedArtists])

    return (
        <HomeWrapper>
            <InnerWrapper>

              {!showSuggestions && (
                <>
                  <SearchWrapper>
                    <Text sx={{fontSize: "2rem",
                      fontWeight: "bold",
                      marginBottom: "1rem"}}>Artist Suche</Text>
                    <SearchBar onInput={(e) => {
                      const searchResult = searchFusionArtists(e.currentTarget.value);
                      setSearchResults(searchResult);
                    }} placeholder={"Oliver Koletzki"}/>
                  </SearchWrapper>
                  <ActOuterWrapper>
                    {searchResults.length > 0 &&
                      (
                        <ActGroup>
                          <ActGroupHeadWrapper>
                            <ActGroupTitle>Suchergebnisse</ActGroupTitle>
                          </ActGroupHeadWrapper>

                          <ActItemWrapper>
                            { searchResults.map((dj, index) => (
                              <ActItemClickWrapper key={`search#${index}`} onClick={() => toggleSelectedArtist(dj)}>
                                <ActItem isSelected={artistIsSelected(dj)}>{dj}</ActItem>
                              </ActItemClickWrapper>
                            ))}
                          </ActItemWrapper>
                        </ActGroup>
                      )
                    }
                    <ActGroup>
                      <ActGroupHeadWrapper>
                        <ActGroupTitle>DJ's</ActGroupTitle>
                        <ActGroupNavWrapper>
                          <ActGroupNavButton onClick={() => {
                            if(djOffset >= ARTIST_LIMIT) setDJOffset(djOffset - ARTIST_LIMIT);
                          }}>{"<"}</ActGroupNavButton>
                          <ActGroupOffsetLabel>{djOffset} - {getMinMaxOfArray(djs, djOffset, ARTIST_LIMIT).max} / {djs.length}</ActGroupOffsetLabel>
                          <ActGroupNavButton onClick={() => {
                            if(djOffset + ARTIST_LIMIT < djs.length) {
                              setDJOffset(djOffset + ARTIST_LIMIT);
                            }
                          }}>{">"}</ActGroupNavButton>
                        </ActGroupNavWrapper>
                      </ActGroupHeadWrapper>

                      <ActItemWrapper>
                        { djs.slice(djOffset, getMinMaxOfArray(djs,djOffset, ARTIST_LIMIT).max).map((dj, index) => (
                          <ActItemClickWrapper key={`dj#${index}`} onClick={() => toggleSelectedArtist(dj)}>
                            <ActItem isSelected={artistIsSelected(dj)}>{dj}</ActItem>
                          </ActItemClickWrapper>
                        ))}
                      </ActItemWrapper>
                    </ActGroup>

                    <ActGroup>
                      <ActGroupHeadWrapper>
                        <ActGroupTitle>Live Acts</ActGroupTitle>
                        <ActGroupNavWrapper>
                          <ActGroupNavButton onClick={() => {
                            if(liveActOffset >= ARTIST_LIMIT) setLiveActOffset(liveActOffset - ARTIST_LIMIT);
                          }}>{"<"}</ActGroupNavButton>
                          <ActGroupOffsetLabel>{liveActOffset} - {getMinMaxOfArray(liveActs, liveActOffset, ARTIST_LIMIT).max} / {liveActs.length}</ActGroupOffsetLabel>
                          <ActGroupNavButton onClick={() => {
                            if(liveActOffset + ARTIST_LIMIT < liveActs.length) {
                              setLiveActOffset(liveActOffset + ARTIST_LIMIT);
                            }
                          }}>{">"}</ActGroupNavButton>
                        </ActGroupNavWrapper>
                      </ActGroupHeadWrapper>

                      <ActItemWrapper>
                        { liveActs.slice(liveActOffset, getMinMaxOfArray(liveActs,liveActOffset, ARTIST_LIMIT).max).map((liveAct, index) => (
                          <ActItemClickWrapper key={`liveAct#${index}`} onClick={() => toggleSelectedArtist(liveAct)}>
                            <ActItem isSelected={artistIsSelected(liveAct)}>{liveAct}</ActItem>
                          </ActItemClickWrapper>
                        ))}
                      </ActItemWrapper>
                    </ActGroup>

                    <ActGroup>
                      <ActGroupHeadWrapper>
                        <ActGroupTitle>Bands</ActGroupTitle>
                        <ActGroupNavWrapper>
                          <ActGroupNavButton onClick={() => {
                            if(bandOffset >= ARTIST_LIMIT) setBandOffset(bandOffset - ARTIST_LIMIT);
                          }}>{"<"}</ActGroupNavButton>
                          <ActGroupOffsetLabel>{bandOffset} - {getMinMaxOfArray(bands, bandOffset, ARTIST_LIMIT).max} / {bands.length}</ActGroupOffsetLabel>
                          <ActGroupNavButton onClick={() => {
                            if(bandOffset + ARTIST_LIMIT < bands.length) {
                              setBandOffset(bandOffset + ARTIST_LIMIT);
                            }
                          }}>{">"}</ActGroupNavButton>
                        </ActGroupNavWrapper>
                      </ActGroupHeadWrapper>

                      <ActItemWrapper>
                        { bands.slice(bandOffset, getMinMaxOfArray(bands,bandOffset, ARTIST_LIMIT).max).map((band, index) => (
                          <ActItemClickWrapper key={`band#${index}`} onClick={() => toggleSelectedArtist(band)}>
                            <ActItem isSelected={artistIsSelected(band)}>{band}</ActItem>
                          </ActItemClickWrapper>
                        ))}
                      </ActItemWrapper>
                    </ActGroup>
                  </ActOuterWrapper>

                  <SuggestionButtonWrapper>
                    <SuggestionButton disabled={selectedArtists.length === 0} onClick={() => {
                      generateSuggestions();
                      history.push("/suggestions");
                    }}>Empfehlungen entdecken</SuggestionButton>
                  </SuggestionButtonWrapper>
                  <ResetButton disabled={selectedArtists.length === 0} onClick={() => setSelectedArtist([])}>Auswahl zurücksetzen</ResetButton>
                </>
              )}
            </InnerWrapper>
        </HomeWrapper>
    );
}

export default Home;