import React, {
  createContext,
  FC, useState,
} from "react";
import {ArtistWithFusionContext} from "../../client/LastFM";
interface IContext {
  selectedArtists: string[];
  setSelectedArtist:(artist: string[]) => void;
  djOffset: number;
  setDJOffset: (offset:number) => void;
  bandOffset: number;
  setBandOffset: (offset:number) => void;
  liveActOffset: number;
  setLiveActOffset: (offset:number) => void;
  suggestions: ArtistWithFusionContext[];
  setSuggestions: (sug: ArtistWithFusionContext[]) => void;
  showSuggestions: boolean;
  setShowSuggestions: (show: boolean) => void;
}

const initialState: IContext = {
  selectedArtists: [],
  setSelectedArtist: () => {},
  djOffset: 0,
  setDJOffset: () => {},
  bandOffset:0,
  setBandOffset: () => {},
  liveActOffset:0,
  setLiveActOffset: () => {},
  suggestions: [],
  setSuggestions: () => {},
  showSuggestions: false,
  setShowSuggestions: () => {},
};
// Create Context Object
const ARTIST_LIMIT = 20;
const SELECTED_ARTISTS_STORAGE_KEY = "selectedArtists"

export const SuggestionContext = createContext(initialState);
const SuggestionContextProvider:FC<{children?: React.ReactNode}> = (props) => {
  const {children} = props;
  const storedSelectedArtists = localStorage.getItem(SELECTED_ARTISTS_STORAGE_KEY);
  const [selectedArtists, setSelectedArtist] = useState<string[]>(storedSelectedArtists ? JSON.parse(storedSelectedArtists) : []);

  const [djOffset, setDJOffset] = useState(0);
  const [bandOffset, setBandOffset] = useState(0);
  const [liveActOffset, setLiveActOffset] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<ArtistWithFusionContext[]>([]);
  return (
    <SuggestionContext.Provider
      value={{
        selectedArtists,
        setSelectedArtist,
        djOffset,setDJOffset,
        bandOffset,
        setBandOffset,
        liveActOffset,
        setLiveActOffset,
        suggestions,
        setSuggestions,
        showSuggestions,
        setShowSuggestions
  }}>
  {children}
  </SuggestionContext.Provider>
);
};

export default SuggestionContextProvider;
