import styled from "@emotion/styled";

export const SuggestionsWrapper = styled.div`
  min-width: 100vw;
  height: auto;
  min-height: 100vh;
  padding: 3rem 2rem;
  box-sizing: border-box;
  display:flex;
  justify-content: center;
`
export const InnerWrapper = styled.div`
  width: 100%;
`