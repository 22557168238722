export const liveActs: string[] = [
  "Acido Pantera",
  "Acusto",
  "addeN",
  "Afrikan Warriors & Sista Livity",
  "Aio",
  "Alexander Kowalski",
  "Aluminé Guerrero",
  "Ameli Paul",
  "Amount",
  "Andi Otto",
  "ANGST vor GRETA",
  "An On Bast",
  "Anthony Hüseyin",
  "Atlantik",
  "Atoem",
  "AVEM",
  "B2B CREW",
  "Bad Girlz",
  "Biesmans",
  "Billy Caso",
  "Bison Rouge",
  "Black Lotus",
  "Bracco",
  "Brother May",
  "Brueder Selke",
  "Canilla",
  "Caxxianne",
  "Chikiss",
  "Chocolate Remix",
  "Christoph Dahlberg",
  "Das Kinn",
  "Der Dritte Raum",
  "DivaDiva",
  "DJ Grace Kelly",
  "Don The Tiger",
  "Drowning Dog & DJ Malatesta",
  "Einmusik",
  "Epsilove",
  "ETHNIQUE PUNCH",
  "Far East & Ras Hassen TI",
  "Faul & Spaeth",
  "Felicity Mangan",
  "Felix Raphael",
  "Fe*Male Treasure",
  "Feral",
  "Fiesta en el Vacio",
  "Freak de l’Afrique",
  "Frikstailers",
  "fuffifufzich",
  "Funkenschleuder",
  "gázel",
  "Gelbart",
  "Giant Swan",
  "Giraffi Dog",
  "Goyanu",
  "Grove",
  "Guedra Guedra",
  "Guido Möbius",
  "Hatikwa",
  "Henrik Schwarz",
  "Holed Coin",
  "Holly North",
  "Hounah",
  "Hyenah feat. King Owusu",
  "Hyriderz",
  "Illustre",
  "ILYICH",
  "Infant Sanchos",
  "Invisible Reality",
  "IPHAZE",
  "Jah Chalice Soundsystem ft. TIMWE & Doc. Dressla",
  "JKSON",
  "Joe Yorke",
  "Joris Delacroix",
  "JPATTERSSON",
  "Jürgen Kurz",
  "Justin",
  "K2W0",
  "Kalipo",
  "Karma She",
  "Kasimir Effekt",
  "Kermesse",
  "Klik & Frik",
  "Kollektiv Turmstrasse",
  "Kunterweiß",
  "KUOKO",
  "Kusht",
  "La Kajofol",
  "Lansky Namek",
  "Laure Boer",
  "Leafar Legov",
  "Lia Şahin",
  "Lia Şahin & Friends",
  "Lila Sovia",
  "L I N",
  "Lisaholic",
  "Liser",
  "L Twills",
  "Luma Lake",
  "Maksim Dark",
  "Marek Hemmann",
  "Martin Kohlstedt",
  "MCR-T",
  "MC Yallah & Debmaster",
  "MEDITATIVE SOUNDS ft. E.C.U.",
  "Mosley Jr.",
  "M-Theory",
  "Mustelide",
  "mytripismytrip",
  "NAH",
  "Nalan",
  "Naptha",
  "NASHI44",
  "Nepumuk",
  "Nepumuk",
  "Nilua",
  "Ninze & Niju",
  "Noema",
  "Nomi Elektra",
  "Numa Gama",
  "Oceanvs Orientalis",
  "Odalie",
  "Ohxala",
  "Oxyflux",
  "O/Y",
  "Paji",
  "Pantha du Prince",
  "Patokai",
  "pHaSenVerScHiEbunGeN",
  "Pöbel MC",
  "Preach/Fathoeburger",
  "Pupkulies & Rebecca",
  "Queenwho",
  "Recondite",
  "Rheremita Cera",
  "Rico Loop",
  "Rincon Sapiência",
  "Robert Babicz",
  "Rosemarie Loves A Blackberry",
  "RP Boo",
  "Ruby My Dear",
  "Saeko Killy",
  "Sainte Vie",
  "Salon de Baile Allstars",
  "Sanga Mama Africa & Black Omolo",
  "SARAH4K, Goldy.mp3 & Doubtboy",
  "Satarii",
  "Schlepp Geist",
  "secret act",
  "Shenanigan",
  "Shkoon",
  "Sho Madjozi",
  "Sidirum",
  "Siren Sister ft. MC Treasure Irie",
  "Sorah + Intare",
  "Soundz of the South",
  "Spoke & Flinta MC's",
  "Stavroz",
  "Ströme",
  "Suetszu",
  "Suetszu",
  "Suitman Jungle",
  "SUN",
  "Sundials & Lapwing (Woodland Rec.)",
  "TAMADA",
  "Tellavision",
  "Terrorrythmus",
  "Tetrameth",
  "They Hate Change",
  "THORD1S",
  "Thylacine",
  "Uzi Freyja",
  "Valentin",
  "Victor Herrero",
  "Viken Arman",
  "Viko63 & Penglord",
  "Wa22ermann",
  "Wandem Sound System & Hornsmen Section",
  "Wareika",
  "Wilted Woman",
  "Yael Lavie",
  "Yaksha Sounds",
  "Yeshe030",
  "Yetundey",
  "Yolanda Frei",
  "Zigan Aldi Collective",
  "Zoe Reijue"
];