import styled from "@emotion/styled";

export const HomeWrapper = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  padding: 3rem 2rem;
  box-sizing: border-box;
  display:flex;
  justify-content: center;
`
export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
`
export const SearchWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  text-align: left;
`

export const ActOuterWrapper = styled.div`
  width:100%;
  height: auto;
  padding: 2.5rem 0;
  box-sizing: border-box;
`

export const ActGroup = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  margin-bottom: 2.5rem;
`
export const ActGroupHeadWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom:1rem;
`
export const ActGroupTitle = styled.h3`
  font-size:1.5rem;
  font-weight:bold;
  margin-bottom:1rem;
`
export const ActGroupNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: min-content;
  align-items: center;
`
export const ActGroupNavButton = styled.button`
  padding: 0.5rem;
  box-sizing: border-box;
  height: min-content;
  border: 1.5px solid black;
  background-color: white;
  font-weight: bold;
  color: black;
`
export const ActGroupOffsetLabel = styled.span`
  font-size:0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
  margin:0 0.5rem;
  height: min-content;
  width: 5rem;
  font-weight: bold;
`

export const ActItemWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  gap: 0.75rem;
  flex-wrap:wrap;
`

export const ActItemClickWrapper = styled.div`
  pointer-events:all;
  position:relative;
`

export const ActItemSuggestionCount = styled.span`
  font-size:0.5rem;
  font-weight:bold;
  color: white;
  background-color: black;
  border-radius: 0.3rem;
  margin:0;
  position:absolute;
  top: -0.35rem;
  right: -0.35rem;
  padding: 0.35rem;
`
export const ActItem = styled.p<{isSelected?:boolean}>`
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  font-size:0.75rem;
  font-weight:bold;
  border: 1.5px solid black;
  border-radius: 0.25rem;
  background-color: ${props => props.isSelected ? "black": "white"};
  color: ${props => props.isSelected ? "white": "black"};
  margin:0;
  cursor:pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
`

export const SuggestionButtonWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`

export const SuggestionButton = styled.button`
  padding: 1.25rem 2.5rem;
  box-sizing: border-box;
  background-color: black;
  color: white;
  font-size:1rem;
  font-weight:bold;
  text-transform: uppercase;
  border:none;
  margin-bottom: 1rem;
  &:disabled {
    opacity: 0.15;
  }
`

export const ResetButton = styled.button`
  padding: 1.25rem 2.5rem;
  box-sizing: border-box;
  background-color: white;
  color: black;
  font-size:1rem;
  font-weight:bold;
  border:none;
  text-decoration:underline;
  &:disabled {
    opacity: 0.15;
  }
`

export const BackNavigationWrapper = styled.div`
  width:100%;
  height: auto;
  display:flex;
  margin-bottom:3rem;
`

export const BackButton = styled.button`
  padding: 0.75rem 1.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: black;
  color: white;
  border: none;
`

export const SuggestionTitle = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 4rem;
`

export const NoSuggestionsFoundTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: #9c9c9c;
`

export const TimeTableDateWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction: column;
`
export const TimeTableDayTitleWrapper = styled.div`
  width: 100%;
  text-align:left;
`
export const TimeTableSlotWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

export const TimeTableSlot = styled.div`
  min-width: 14rem;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  border: 1.5px dashed #d4d4d4;
`
export const TimeSlotTitle = styled.p`
  width: 100%;
  font-size:1rem;
  font-weight:bold;
  text-align: center;
  margin: 0 0 1.25rem;
`
export const TimeSlotGroup = styled.div`
  width:100%;
  margin-bottom: 1rem;
  &:last-of-type{
    margin-bottom:0;
  }
`
export const StageTitle = styled.p`
  width: 100%;
  font-size:0.5rem;
  font-weight:bold;
  text-align: center;
  margin: 0 0 0.75rem;
`